<template>
	<div class="page-width">
		<div class="flex flex_align_cneter">
			<input v-model="supplier_id" type="text" placeholder="Supplier ID" class="margin_0">
			<a @click="searchSlotsBySupplierId" class="button is-info supplier_id_button">Submit</a>
		</div>
		<datatable title="Slots" :response="data" :fields="fields" :filters="filters" :is_createable="false">
		</datatable>
	</div>
</template>
<script>
	import { mapMutations } from 'vuex'
	import FiltersHelper from '@/classes/filters_helper.js'
	import SlotsHelper from '@/classes/slots_helper.js'
	import Datatable from '@/components/Datatable'
	export default {
		name: 'companies',
		data(){
			let slots_helper = new SlotsHelper()
			let filters_helper = new FiltersHelper()
			return {
				supplier_id: this.$route.query.supplier_id || '',
				filters_helper,
				fields: [
				    {
				      name: 'Status',
				      value: 'status',
				      component: 'SlotStatus'
				    },
				    {
				      name: 'Position',
				      value: 'position.title',
				      component: 'LinkEntity',
				      component_data_path: 'position',
				      additional_data: {
				      	resource_type: 'Position'
				      }
				    },
				    {
				    	name: 'Env',
				    	value: 'position.env',
				    },
				    {
				      name: 'Outsource_Class',
				      value: 'supplier.outsource_class'
				    },
				    {
				      name: 'Deliveries',
				      value: 'deliveries'
				    },
				    {
				    	name: 'delivered_at',
				    	value: 'delivered_at',
				    	is_time: true
				    },
				    {
				      name: 'created_at',
				      value: 'created_at',
				      is_time: true
				    },
				],
				filters: [
			        {
			          type: 'single',
			          designator: 'env',
			          options: filters_helper.getEnvsAsFilters()
			        },
			        {
			          type: 'single',
			          designator: 'outsource_class',
			          options: filters_helper.getOutsourcesAsFilters()
			        },
					{
					  type: 'multiple',
					  designator: 'statuses',
					  options: slots_helper.getFiltersOptions()
					},
				],
				data: [],
			}
		},
		created(){
			this.$network_utils.getSlots({
				supplier_id: this.$route.query.supplier_id,
				position_id: this.$route.query.position_id,
          		...this.filters_helper.getServerCallFilters(this.filters),
			}).then(response => {
          		response = Array.isArray(response) ? response : [response]
				this.data = response
			})
		},
		methods: {
			searchSlotsBySupplierId(){
				let query = { ...this.$route.query }
				query.supplier_id = this.supplier_id.length > 0 ? this.supplier_id : undefined
				this.$router.push({ name: 'slots', query })
			},
			...mapMutations('modals', ['createModal'])
		},
		components: {
			Datatable
		}
	}
</script>
<style scoped>
	.supplier_id_button {
		font-size: 13px;
		margin-left: 10px;
	}
</style>